.ant-btn {
  &.action-btn-delete {
    color: @delete-action-color;
    background-color: @delete-action-bg;
    border-color: @delete-action-bdcolor;
    &:hover {
      color: @delete-action-color;
      background-color: @delete-action-bg;
      border-color: @delete-action-bdcolor-hover;
    }
    r&:active {
      color: @delete-action-color;
      background-color: @delete-action-bg;
      border-color: @delete-action-bdcolor-hover;
    }
    &:focus {
      color: @delete-action-color;
      background-color: @delete-action-bg;
      border-color: @delete-action-bdcolor-hover;
    }
  }
  &.action-btn-edit {
    color: @edit-action-color;
    background-color: @edit-action-bg;
    border-color: @edit-action-bdcolor;
    &:hover {
      color: @edit-action-color;
      background-color: @edit-action-bg;
      border-color: @edit-action-bdcolor-hover;
    }
    &:active {
      color: @edit-action-color;
      background-color: @edit-action-bg;
      border-color: @edit-action-bdcolor-hover;
    }
    &:focus {
      color: @edit-action-color;
      background-color: @edit-action-bg;
      border-color: @edit-action-bdcolor-hover;
    }
  }
  &.action-btn-create {
    color: @create-action-color;
    background-color: @create-action-bg;
    border-color: @create-action-bdcolor;
    &:hover {
      color: @create-action-color-light;
      background-color: @create-action-bg-light;
      border-color: @create-action-bdcolor-light;
    }
    &:active {
      color: @create-action-color-light;
      background-color: @create-action-bg-light;
      border-color: @create-action-bdcolor-light;
    }
    &:focus {
      color: @create-action-color-light;
      background-color: @create-action-bg-light;
      border-color: @create-action-bdcolor-light;
    }
  }
  &.action-btn-activate {
    color: @activate-action-color;
    background-color: @activate-action-bg;
    border-color: @activate-action-bdcolor;
    &:hover {
      color: @activate-action-color;
      background-color: @activate-action-bg-hover;
      border-color: @activate-action-bdcolor;
    }
    &:active {
      color: @activate-action-color;
      background-color: @activate-action-bg-hover;
      border-color: @activate-action-bdcolor;
    }
    &:focus {
      color: @activate-action-color;
      background-color: @activate-action-bg-hover;
      border-color: @activate-action-bdcolor;
    }
  }

  &.action-btn-replicate {
    color: @replicate-action-color;
    background-color: @replicate-action-bg;
    border-color: @replicate-action-bdcolor;
    &:hover {
      color: @replicate-action-color;
      background-color: @replicate-action-bg-hover;
      border-color: @replicate-action-bdcolor;
    }
    &:active {
      color: @replicate-action-color;
      background-color: @replicate-action-bg-hover;
      border-color: @replicate-action-bdcolor;
    }
    &:focus {
      color: @replicate-action-color;
      background-color: @replicate-action-bg-hover;
      border-color: @replicate-action-bdcolor;
    }
  }
  &.action-btn-approve {
    color: @approve-action-color;
    background-color: @approve-action-bg;
    border-color: @approve-action-bdcolor;
    &:hover {
      color: @approve-action-color;
      background-color: @approve-action-bg-hover;
      border-color: @approve-action-bdcolor;
    }
    &:active {
      color: @approve-action-color;
      background-color: @approve-action-bg-hover;
      border-color: @approve-action-bdcolor;
    }
    &:focus {
      color: @approve-action-color;
      background-color: @approve-action-bg-hover;
      border-color: @approve-action-bdcolor;
    }
  }
  &.action-btn-pause {
    color: @pause-action-color;
    background-color: @pause-action-bg;
    border-color: @pause-action-bdcolor;
    &:hover {
      color: @pause-action-color;
      background-color: @pause-action-bg;
      border-color: @pause-action-bdcolor-hover;
    }
    &:active {
      color: @pause-action-color;
      background-color: @pause-action-bg;
      border-color: @pause-action-bdcolor-hover;
    }
    &:focus {
      color: @pause-action-color;
      background-color: @pause-action-bg;
      border-color: @pause-action-bdcolor-hover;
    }
  }
  &.action-btn-complete {
    color: @complete-action-color;
    background-color: @complete-action-bg;
    border-color: @complete-action-bdcolor;
    &:hover {
      color: @complete-action-color;
      background-color: @complete-action-bg;
      border-color: @complete-action-bdcolor-hover;
    }
    &:active {
      color: @complete-action-color;
      background-color: @complete-action-bg;
      border-color: @complete-action-bdcolor-hover;
    }
    &:focus {
      color: @complete-action-color;
      background-color: @complete-action-bg;
      border-color: @complete-action-bdcolor-hover;
    }
  }
  &.action-btn-archive {
    color: @archive-action-color;
    background-color: @archive-action-bg;
    border-color: @archive-action-bdcolor;
    &:hover {
      color: @archive-action-color;
      background-color: @archive-action-bg;
      border-color: @archive-action-bdcolor;
    }
    &:active {
      color: @archive-action-color;
      background-color: @archive-action-bg;
      border-color: @archive-action-bdcolor;
    }
    &:focus {
      color: @archive-action-color;
      background-color: @archive-action-bg;
      border-color: @archive-action-bdcolor;
    }
  }
  &.action-btn-submit {
    color: @submit-action-color;
    background-color: @submit-action-bg;
    border-color: @submit-action-bdcolor;
    &:hover {
      color: @submit-action-color;
      background-color: @submit-action-bg-hover;
      border-color: @submit-action-bdcolor;
    }
    &:active {
      color: @submit-action-color;
      background-color: @submit-action-bg-hover;
      border-color: @submit-action-bdcolor;
    }
    &:focus {
      color: @submit-action-color;
      background-color: @submit-action-bg-hover;
      border-color: @submit-action-bdcolor;
    }
  }
  &.action-btn-reject {
    color: @reject-action-color;
    background-color: @reject-action-bg;
    border-color: @reject-action-bdcolor;
    &:hover {
      color: @reject-action-color;
      background-color: @reject-action-bg;
      border-color: @reject-action-bdcolor-hover;
    }
    &:active {
      color: @reject-action-color;
      background-color: @reject-action-bg;
      border-color: @reject-action-bdcolor-hover;
    }
    &:focus {
      color: @reject-action-color;
      background-color: @reject-action-bg;
      border-color: @reject-action-bdcolor-hover;
    }
  }
  &.action-btn-pending_approval {
    &:hover {
      color: @pending_approval-action-color-light;
      background-color: @pending_approval-action-bg-light;
      border-color: @pending_approval-action-bdcolor-light;
    }
    &:active {
      color: @pending_approval-action-color;
      background-color: @pending_approval-action-bg-light;
      border-color: @pending_approval-action-bdcolor-light;
    }
    &:focus {
      color: @pending_approval-action-color-light;
      background-color: @pending_approval-action-bg-light;
      border-color: @pending_approval-action-bdcolor-light;
    }
  }
}
