.form-page-container {
  .page-content {
    .ant-input {
      height: 42px;
      border-radius: 8px;
      padding-top: 7px;
    }
    .ant-upload {
      width: 100%;
    }
    .add-locale-btn {
      height: 42px;
      border-radius: 8px;
      width: 100%;
    }
    .csv-uploader-btn {
      color: #0197fe;
      border-radius: 8px;
      height: 42px;
      width: 100%;
    }
    .template-btn {
      font-size: 12px;
      text-align: left;
    }
    .color-code {
      padding-left: 15px;
    }
    .translation-or {
      padding: 10px 33% 0 33%;
      align-items: center;
    }
  }
  .status-tag {
    float: right;
    text-align: center;
    .status-specific {
      font-size: 10px;
    }
    .tag-label {
      width: 90px;
      height: 25px;
    }
  }
  .step-menu-row {
    margin: 24px 24px 0 24px;
    .step-menu {
      width: 400px;
      float: right;
      .ant-steps-item-content {
        // width: 160px;
      }
      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #e8e8e8;
      }
    }
  }
  .new-campaign {
    margin: 20px 24px 0 24px;
    .header-action-container {
      text-align: right;
      // padding-right: 32px;
      button[class^='ant-btn action-btn'],
      button[class^='ant-btn ant-btn-primary action-btn'] {
        &:not(:first-child) {
          margin-left: 16px;
        }
        min-width: 126px;
        // height: 42px;
        border-radius: 8px;
        i {
          margin-right: 8px;
        }
        span {
          // min-width: 48px;
          // margin-left: 8px;
          // display: inline-block;
          // text-align: center;
        }
      }
    }
  }
  .form-switch {
    background-color: rgb(1, 202, 254);
  }
}

// Disable asterix in the back of required label form field
.ant-form-item-no-colon.ant-form-item-required::before,
.ant-form-item-required::before {
  display: none !important;
}
// Add asterix in the front of required label form field
.ant-form-item-label {
  text-align: left !important;
  white-space: normal;
  > label {
    display: inline-table;
  }
  > label.ant-form-item-no-colon.ant-form-item-required::after,
  > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
