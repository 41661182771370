.ant-modal.modal-dialog {
  .ant-modal-content {
    width: 493px;
    height: auto;
    .ant-modal-body {
      font-size: 14px;
      text-align: justify;
      .anticon {
        float: left;
        font-size: 17px;
        margin-right: 7px;
        &.anticon-loading {
          color: #1890ff;
        }
      }
      .dialog-title {
        display: block;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
      }
      .dialog-description {
        display: block;
        margin-left: 36px;
        margin-top: 12px;
      }
      .info-title {
        &:not(:first-child) {
          padding-top: 12px;
          display: inline-block;
        }
      }
      .info-details {
        font-size: 14px;
        line-height: 1.2;
        .ant-tag {
          margin-top: 4px;
          margin-right: 4px;
        }
      }
    }
    .ant-modal-footer {
      .ant-btn.delete,
      .ant-btn.reject,
      .ant-btn.discard {
        color: #ec3c3e;
        background-color: #fff;
        border-color: #ff4d4f;
        &:hover {
          color: #fff;
          background-color: #d9363e;
          border-color: #ff7875;
        }
        &:active {
          color: #fff;
          background-color: #d9363e;
          border-color: #d9363e;
        }
        &:focus {
          color: #fff;
          background-color: #ff7875;
          border-color: #ff7875;
        }
      }
    }
  }
}
