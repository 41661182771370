@font-face {
  font-family: 'Samsung Sharp Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Regular'),
    url('font/samsungsharpsans.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Medium'),
    url('font/samsungsharpsans-medium.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Bold'),
    url('font/samsungsharpsans-bold.woff') format('woff');
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: normal;
  src: local('SamsungOne'), url('font/SamsungOne-400.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(font/Roboto-Medium_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url(font/Roboto-Regular_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  src: url(font/RobotoCondensed-Bold_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Light';
  src: url(font/RobotoCondensed-Light_1.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed Regular';
  src: url(font/RobotoCondensed-Regular_1.ttf) format('truetype');
}

body,
body > #root {
  font-family: 'Samsung Sharp Sans Medium';
}

.ant-menu-dark .ant-menu-item-selected > a {
  color: #01cafe;
}

.ant-menu-dark .ant-menu-item-active > a {
  color: #01cafe;
}

.ant-menu-dark .ant-menu-item-active .sw-nav-title {
  color: #01cafe;
}

.ant-menu-dark .ant-menu-item-active .sub-menu {
  color: #01cafe;
}

.ant-menu-dark .ant-menu-submenu-active .ant-menu-submenu-title {
  color: #01cafe;
}

.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #01cafe;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a .anticon {
  display: none;
}

.ant-collapse-borderless {
  background-color: white !important;
}

.ant-dropdown-menu.sw-user-menu .anticon.anticon-logout {
  margin-right: 8px;
}

.preset-icon {
  background-color: #01cafe;
  color: #404040;
  border-radius: 38%;
  width: 18px;
  height: 18px;
  line-height: 19px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
  vertical-align: middle;
  margin-bottom: 6px;
}

@keyframes backgroundChanger {
  0% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 60%
    );
  }
  1% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 68%
    );
  }
  2% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 76%
    );
  }
  3% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 84%
    );
  }
  4% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 92%
    );
  }
  5% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 100%
    );
  }
  6% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 92%
    );
  }
  7% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 84%
    );
  }
  8% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 76%
    );
  }
  9% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 68%
    );
  }
  10% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 60%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(10, 24, 110, 1) 0%,
      rgba(52, 165, 254, 1) 0%,
      rgba(10, 24, 110, 1) 60%
    );
  }
}

.stcms-2-button {
  background: linear-gradient(
    90deg,
    rgba(10, 24, 110, 1) 0%,
    rgba(52, 165, 254, 1) 0%,
    rgba(10, 24, 110, 1) 60%
  );
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
  width: 150px !important;
  animation: backgroundChanger 11s infinite;
}

.stcms-2-button:hover {
  background: linear-gradient(
    90deg,
    rgba(10, 24, 110, 1) 0%,
    rgba(52, 165, 254, 1) 0%,
    rgba(10, 24, 110, 1) 60%
  );
}

.login_card .ant-card-body {
  padding: 2.4vh;
}

.ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  top: 30px;
  height: calc(100vh - 30px);
  position: fixed;
  width: 100%;
}

.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root {
  height: calc(100vh - 228px);
  scrollbar-width: none;
}

.ant-layout-sider.sw-side-nav.sw-side-nav-fixed
  .ant-menu-root::-webkit-scrollbar {
  width: 0;
}

.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-inline-collapsed {
  height: calc(100vh - 94px);
}

.gse-table .ant-table-thead {
  white-space: nowrap;
}

@keyframes footerAnimation {
  0% {
    width: 80px;
    height: 60px;
  }
  100% {
    width: 275px;
    height: 60px;
  }
}

.side-nav-footer-container {
  width: 275px;
  height: 60px;
  padding-top: 15px;
  background: #373540;
  position: fixed;
  color: white;
  bottom: 0;
  left: 0;
  z-index: 20;
  animation: 0.25s ease 0s 1 footerAnimation;
}

.modal-service-role {
  width: 60vw !important;
}

.popup-modal {
  width: 70vw !important;
}

.modal-service-role .ant-modal-content {
  border-radius: 10px;
}

.modal-service-role .ant-modal-header {
  background-color: #0a186e;
}

.modal-service-role .ant-modal-title {
  color: #ffffff;
  font-weight: 900;
}

.modal-service-role .ant-modal-close-x .anticon svg {
  width: 1.3rem;
  height: auto;
}

.button-primary {
  background: #0a186e !important;
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:hover {
  background: #454c7a !important;
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:disabled {
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  border: 1px solid #b8b8b8 !important;
  color: #b8b8b8 !important;
}

.notice-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 20px;
}

.notice-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.notice-date {
  font-family: Helvetica;
  font-size: 12px;
  color: black;
}

.notice-message {
  font-family: Helvetica;
  margin-top: 20px;
  font-size: 14px;
  color: black;
  text-align: justify;
}

.notice-modal .ant-modal-header {
  background-color: #0197fe;
}

.notice-modal .ant-modal-title {
  color: #ffffff;
  font-weight: bold;
}

.sw-top-nav-items {
  display: flex;
  align-items: center;
}

.ant-badge {
  margin-right: 8px !important;
}

section.sw-layout-inner.ant-layout.content-full {
  padding-left: 275px !important;
  margin-top: 30px;
  width: 100%;
}

section.sw-layout-inner.ant-layout.content-collapse {
  padding-left: 80px !important;
  margin-top: 30px;
  width: 100%;
}
