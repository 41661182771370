.sw-side-nav.ant-layout-sider {
  // height: 50px;
  background: #28262f;
  .ant-menu-item .anticon,
  .ant-menu-submenu .ant-menu-submenu-title .anticon {
    font-size: 18px;
  }
  .sw-nav-logo {
    height: 50px;
    line-height: 64px;
    align-items: center;
    padding-left: 0;
    width: 50px;
    background: #28262f;
    font-size: 25px;
    img {
      padding: 1px;
      height: 50px;
      width: 250px;
    }
  }
  .sw-side-nav-menu {
    background: #373540;
    .ant-menu-item-selected {
      background: #28262f;
    }
    .ant-menu-sub {
      background: #373540;
      box-shadow: none;
    }
  }
}

.dashboardTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
  color: #272727;
}
.dashboardSubTitle {
  font-size: 16px;
  margin: 0px;
  color: #979797;
}
.dashboardDescription {
  font-size: 12px;
  margin: 0px;
  color: #979797;
}

.filledLightBlue {
  border: none !important;
  background-color: #01cafe !important;
  color: #ffffff !important;
}
.filledLightBlue:hover,
.filledLightBlue:focus {
  border: none !important;
  background-color: #4ebaeb !important;
  color: #ffffff !important;
}
.filledDarkBlue {
  border: none !important;
  background-color: #0197fe !important;
  color: #ffffff !important;
}
.filledDarkBlue:hover,
.filledDarkBlue:focus {
  border: none !important;
  background-color: #0c88dd !important;
  color: #ffffff !important;
}
.ghostGrey {
  border: #c1c1c1 solid 1px !important;
  background-color: #f1f1f1 !important;
  color: #0197fe !important;
}
.ghostGrey:hover,
.ghostGrey:focus {
  border: #0197fe solid 1px !important;
  background-color: #f1f1f1 !important;
  color: #0197fe !important;
}
.ghostWhite {
  border: #c1c1c1 solid 1px !important;
  background-color: #fff !important;
  color: #0197fe !important;
}
.ghostWhite:hover,
.ghosWhite:focus {
  border: #0197fe solid 1px !important;
  background-color: #fff !important;
  color: #0197fe !important;
}

.nudeBlue {
  border: none !important;
  background-color: transparent !important;
  color: #0197fe !important;
  border-radius: 50px;
}
.nudeBlue:hover,
.nudeBlue:focus {
  border: none !important;
  background-color: #e4f4ff !important;
  color: #0197fe !important;
  border-radius: 50px;
}
.nudeGrey {
  border: none !important;
  background-color: transparent !important;
  color: #8d8d8d !important;
  border-radius: 50px;
}
.nudeGrey:hover,
.nudeGrey:focus {
  border: none !important;
  background-color: #f5f5f5 !important;
  color: #8d8d8d !important;
  border-radius: 50px;
}

.ant-btn {
  border-radius: 4px;
}

.ant-affix {
  height: auto !important;
}

.ant-radio-group {
  font-size: 14px;
}

.ant-select-lg,
.ant-input-lg,
.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
}

.ant-form-item {
  // margin-bottom: 32px;
}
.ant-form-item-with-help {
  margin-bottom: 8px;
}
.ant-input,
.ant-input-number,
.ant-input-number .ant-input-number-handler-wrap {
  border-radius: 7px;
}

.ant-form-item-control-input {
  .sw-field-auto-complete .ant-select-selector,
  .ant-select .ant-select-selector,
  .ant-picker {
    border-radius: 7px !important;
  }
}

.sw-table-tools-top .sw-table-tools-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
  // flex: auto;
  // text-align: right;
  .ant-input-group-addon {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    .ant-select-selection-item {
      text-align: left;
      .sw-table-filter-select {
        height: 30px;
      }
    }
  }
  .ant-input-affix-wrapper {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .ant-input {
      height: 30px;
    }
  }
  .ant-input-affix-wrapper.sw-table-query {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .ant-input {
      height: 30px;
    }
  }
}

.ant-col .ant-cascader .ant-select-selector,
.field-input-antd {
  border-radius: 7px;
}

.ant-tabs-tab .anticon {
  margin-right: 4px;
}
