/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sw-country-svg {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  height: 1em;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 1em;
  line-height: 1em;
}
.sw-lang-select {
  cursor: pointer;
}
.sw-lang-select-menu .sw-lang-select-item .sw-country-emoji,
.sw-lang-select-menu .sw-lang-select-item .sw-country-svg {
  margin-right: 8px;
  vertical-align: middle;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix {
  font-size: 18px;
  cursor: pointer;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-focused,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-focused.input-hovered,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.anticon:active,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.anticon:hover {
  color: #40a9ff;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-hovered,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-hovered:hover {
  color: rgba(24, 144, 255, 0.5);
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.hidden {
  display: none;
  visibility: hidden;
}
.sw-form-actions .sw-form-action {
  float: right;
}
.sw-form-actions .sw-form-action:not(:first-of-type) {
  margin-right: 10px;
}
.sw-auth-view {
  height: 1px;
  min-height: 100vh;
  background: linear-gradient(to bottom, #096dd9 50%, #1890ff 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.sw-auth-view .sw-auth-view-lang {
  padding: 8px 16px;
}
.sw-auth-view .sw-auth-view-lang .sw-auth-view-lang-col {
  text-align: right;
}
.sw-auth-view .sw-auth-box {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: #f0f2f5;
  padding: 1em;
}
.sw-auth-main .sw-auth-title {
  text-align: center;
}
.sw-auth-main .sw-auth-btn {
  margin-bottom: 2.5px;
}
.sw-auth-main .sw-auth-btn:not(:first-of-type) {
  margin-top: 2.5px;
}
.sw-login-lock.ant-avatar {
  background-color: #ff4d4f;
}
.sw-login-forgot-field-link {
  text-align: right;
}
.sw-login-forgot-btn {
  display: inherit;
  margin-top: 2.5px;
  text-align: center;
}
.sw-reg-form .sw-reg-login-btn {
  display: inherit;
  margin-top: 2.5px;
  text-align: center;
}
.ant-layout.sw-layout .sw-layout-inner {
  min-height: 100vh;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout.sw-layout.sw-layout-b .sw-layout-inner {
  min-height: calc(100vh - 64px);
}
.ant-layout.sw-layout .sw-layout-footer {
  padding: 0;
}
.ant-layout.sw-layout .sw-layout-footer .sw-footer-main {
  padding: 0 16px;
  margin: 24px 0;
  text-align: center;
}
.ant-layout.sw-layout .sw-layout-content {
  margin: 24px;
}
.ant-layout.sw-layout .sw-layout-content.sw-layout-content-fixed {
  padding-top: 64px;
}
.ant-layout.sw-layout .sw-layout-content .sw-layout-basic-content {
  margin: -24px;
}
.sw-page-content {
  margin: 24px 24px 0;
}
.sw-page-content .sw-page-main {
  width: 100%;
  transition: 0.3s;
}
@media screen and (max-width: 576px) {
  .sw-page-content {
    margin: 24px 0 0;
  }
}
.sw-page-card {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.sw-page-header {
  background: #fff;
  padding: 16px 24px 0;
  border-bottom: 1px solid #e8e8e8;
}
.sw-page-header .sw-page-breadcrumb {
  margin-bottom: 16px;
}
.sw-page-header .sw-page-breadcrumb .anticon {
  margin-right: 4px;
}
.sw-page-header .sw-page-header-detail {
  display: flex;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container {
  flex: 0 1 auto;
  width: 100%;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container .sw-page-header-row {
  display: flex;
  width: 100%;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container .sw-page-header-row .sw-page-title {
  flex: auto;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.ant-layout-sider.sw-side-nav .sw-nav-logo,
.ant-layout-header.sw-layout-header .sw-nav-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.ant-layout-sider.sw-side-nav .sw-nav-logo img,
.ant-layout-header.sw-layout-header .sw-nav-logo img {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
}
.ant-layout-sider.sw-side-nav .sw-nav-logo h1,
.ant-layout-header.sw-layout-header .sw-nav-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-weight: 600;
}
.ant-layout-header.sw-layout-header {
  padding: 0;
  width: 100%;
}
.ant-layout-header.sw-layout-header.sw-layout-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.ant-layout-header.sw-layout-header .sw-top-nav {
  height: 64px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-nav-logo {
  width: 256px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-side-nav-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: 22px 24px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-menu {
  flex: 1;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-menu.ant-menu-horizontal {
  border-bottom: none;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-lang-select,
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
  padding: 0 12px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-avatar {
  margin: 20px 8px 20px 0;
  vertical-align: top;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role {
  display: inline-block;
  vertical-align: top;
  margin: 16px 0;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role .sw-user-drop-name,
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role .sw-user-drop-role {
  display: block;
  line-height: 16px;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-side-nav-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-top-nav-items .ant-dropdown-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo {
  background: #fff;
  border-right: solid 1px #e8e8e8;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo h1 {
  color: #1890ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo h1:hover {
  color: #40a9ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark {
  background: #001529;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  color: rgba(255, 255, 255, 0.65);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark .sw-side-nav-trigger:hover {
  color: #fff;
  background: #1890ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark .sw-top-nav-items .ant-dropdown-trigger:hover {
  color: #fff;
  background: #1890ff;
}
.ant-layout.sw-layout.sw-layout-b .ant-layout-header.sw-layout-header {
  z-index: 11;
}
.ant-layout-sider.sw-side-nav {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout-sider.sw-side-nav .sw-side-nav-menu {
  padding-bottom: 16px;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root.is-mobile {
  height: calc(100vh - 110px);
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo {
  background: #fff;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo h1 {
  color: #1890ff;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo h1:hover {
  color: #40a9ff;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .ant-layout-sider-trigger {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.ant-drawer.sw-drawer-side-nav .ant-drawer-body {
  padding: 0;
  height: 100vh;
}
.ant-layout.sw-layout.sw-layout-b .ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  top: 64px;
}
.sw-page-error {
  text-align: center;
  display: block;
  position: relative;
  width: 450px;
  margin: 64px auto;
}
.sw-page-error-code {
  color: #1890ff;
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}
.sw-page-error hr {
  padding: 0;
  border: none;
  border-top: 5px solid rgba(0, 0, 0, 0.85);
  text-align: center;
  margin: 8px auto;
  width: 420px;
  height: 10px;
  z-index: -10;
}
.sw-page-error hr::after {
  content: '\2022';
  display: inline-block;
  position: relative;
  top: -0.85em;
  font-size: 2em;
  padding: 0 0.2em;
  background: #f0f2f5;
  color: rgba(0, 0, 0, 0.85);
}
.sw-page-error-1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
  text-transform: uppercase;
}
.sw-page-error-2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
}
.sw-page-error-btn {
  position: relative;
  display: inline-block;
  width: 358px;
  margin-top: 8px;
  text-transform: uppercase;
}
.sw-table-root,
.sw-table-tools,
.sw-table-container {
  width: 100%;
}
.sw-table-root.is-mobile,
.sw-table-tools.is-mobile,
.sw-table-container.is-mobile {
  max-height: 100%;
}
.sw-table-tools {
  display: flex;
  flex-direction: column;
}
.sw-table-tools .sw-table-info {
  margin-top: 12px;
}
.sw-table-tools .sw-table-tools-top {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-actions {
  flex: 0 0 auto;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons {
  flex: auto;
  text-align: right;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-affix-wrapper,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-group-wrapper {
  max-width: 320px;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input .ant-input-suffix,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-affix-wrapper .ant-input-suffix,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-group-wrapper .ant-input-suffix {
  cursor: pointer;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .anticon {
  font-size: 16px;
  padding: 6px 0;
  vertical-align: middle;
  cursor: pointer;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .anticon:hover {
  color: #40a9ff;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-divider-vertical {
  height: 1.1em;
  margin: 0 6px;
}
.sw-table-filter-overlay .sw-table-filter-reset,
.sw-table-query-overlay .sw-table-query-reset,
.sw-table-actions-mobile {
  cursor: pointer;
}
.sw-table-container .sw-column-action .sw-row-action-item-icon {
  font-size: 16px;
}
.sw-table-container .sw-column-action .sw-row-action-item {
  color: #1890ff;
  text-decoration: none;
}
.sw-table-container .sw-column-action .sw-row-action-item,
.sw-table-container .sw-column-action .sw-row-action-item-icon {
  cursor: pointer;
}
.sw-table-container .sw-column-action .sw-row-action-item:hover,
.sw-table-container .sw-column-action .sw-row-action-item-icon:hover {
  color: #40a9ff;
}
.sw-table-container .sw-column-action .anticon.ant-dropdown-trigger {
  cursor: pointer;
  color: #1890ff;
  text-decoration: none;
  font-size: 16px;
}
.sw-table-container .sw-column-action .anticon.ant-dropdown-trigger:hover {
  color: #40a9ff;
}
@font-face {
  font-family: 'Samsung Sharp Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Regular'), url('font/samsungsharpsans.woff') format('woff');
}
@font-face {
  font-family: 'Samsung Sharp Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Medium'), url('font/samsungsharpsans-medium.woff') format('woff');
}
@font-face {
  font-family: 'Samsung Sharp Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Bold'), url('font/samsungsharpsans-bold.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: normal;
  src: local('SamsungOne'), url('font/SamsungOne-400.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Medium';
  src: url(font/Roboto-Medium_1.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Regular';
  src: url(font/Roboto-Regular_1.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed Bold';
  src: url(font/RobotoCondensed-Bold_1.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed Light';
  src: url(font/RobotoCondensed-Light_1.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed Regular';
  src: url(font/RobotoCondensed-Regular_1.ttf) format('truetype');
}
body,
body > #root {
  font-family: 'Samsung Sharp Sans Medium';
}
.ant-menu-dark .ant-menu-item-selected > a {
  color: #01cafe;
}
.ant-menu-dark .ant-menu-item-active > a {
  color: #01cafe;
}
.ant-menu-dark .ant-menu-item-active .sw-nav-title {
  color: #01cafe;
}
.ant-menu-dark .ant-menu-item-active .sub-menu {
  color: #01cafe;
}
.ant-menu-dark .ant-menu-submenu-active .ant-menu-submenu-title {
  color: #01cafe;
}
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #01cafe;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a .anticon {
  display: none;
}
.ant-collapse-borderless {
  background-color: white !important;
}
.ant-dropdown-menu.sw-user-menu .anticon.anticon-logout {
  margin-right: 8px;
}
.preset-icon {
  background-color: #01cafe;
  color: #404040;
  border-radius: 38%;
  width: 18px;
  height: 18px;
  line-height: 19px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
  vertical-align: middle;
  margin-bottom: 6px;
}
@keyframes backgroundChanger {
  0% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 60%);
  }
  1% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 68%);
  }
  2% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 76%);
  }
  3% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 84%);
  }
  4% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 92%);
  }
  5% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 100%);
  }
  6% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 92%);
  }
  7% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 84%);
  }
  8% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 76%);
  }
  9% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 68%);
  }
  10% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 60%);
  }
  100% {
    background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 60%);
  }
}
.stcms-2-button {
  background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 60%);
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
  width: 150px !important;
  animation: backgroundChanger 11s infinite;
}
.stcms-2-button:hover {
  background: linear-gradient(90deg, #0a186e 0%, #34a5fe 0%, #0a186e 60%);
}
.login_card .ant-card-body {
  padding: 2.4vh;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  top: 30px;
  height: calc(100vh - 30px);
  position: fixed;
  width: 100%;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root {
  height: calc(100vh - 228px);
  scrollbar-width: none;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root::-webkit-scrollbar {
  width: 0;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-inline-collapsed {
  height: calc(100vh - 94px);
}
.gse-table .ant-table-thead {
  white-space: nowrap;
}
@keyframes footerAnimation {
  0% {
    width: 80px;
    height: 60px;
  }
  100% {
    width: 275px;
    height: 60px;
  }
}
.side-nav-footer-container {
  width: 275px;
  height: 60px;
  padding-top: 15px;
  background: #373540;
  position: fixed;
  color: white;
  bottom: 0;
  left: 0;
  z-index: 20;
  animation: 0.25s ease 0s 1 footerAnimation;
}
.modal-service-role {
  width: 60vw !important;
}
.popup-modal {
  width: 70vw !important;
}
.modal-service-role .ant-modal-content {
  border-radius: 10px;
}
.modal-service-role .ant-modal-header {
  background-color: #0a186e;
}
.modal-service-role .ant-modal-title {
  color: #ffffff;
  font-weight: 900;
}
.modal-service-role .ant-modal-close-x .anticon svg {
  width: 1.3rem;
  height: auto;
}
.button-primary {
  background: #0a186e !important;
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:hover {
  background: #454c7a !important;
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
}
.button-primary:disabled {
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  border: 1px solid #b8b8b8 !important;
  color: #b8b8b8 !important;
}
.notice-modal .ant-modal-content {
  overflow: hidden;
  border-radius: 20px;
}
.notice-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.notice-date {
  font-family: Helvetica;
  font-size: 12px;
  color: black;
}
.notice-message {
  font-family: Helvetica;
  margin-top: 20px;
  font-size: 14px;
  color: black;
  text-align: justify;
}
.notice-modal .ant-modal-header {
  background-color: #0197fe;
}
.notice-modal .ant-modal-title {
  color: #ffffff;
  font-weight: bold;
}
.sw-top-nav-items {
  display: flex;
  align-items: center;
}
.ant-badge {
  margin-right: 8px !important;
}
section.sw-layout-inner.ant-layout.content-full {
  padding-left: 275px !important;
  margin-top: 30px;
  width: 100%;
}
section.sw-layout-inner.ant-layout.content-collapse {
  padding-left: 80px !important;
  margin-top: 30px;
  width: 100%;
}
.sw-side-nav.ant-layout-sider {
  background: #28262f;
}
.sw-side-nav.ant-layout-sider .ant-menu-item .anticon,
.sw-side-nav.ant-layout-sider .ant-menu-submenu .ant-menu-submenu-title .anticon {
  font-size: 18px;
}
.sw-side-nav.ant-layout-sider .sw-nav-logo {
  height: 50px;
  line-height: 64px;
  align-items: center;
  padding-left: 0;
  width: 50px;
  background: #28262f;
  font-size: 25px;
}
.sw-side-nav.ant-layout-sider .sw-nav-logo img {
  padding: 1px;
  height: 50px;
  width: 250px;
}
.sw-side-nav.ant-layout-sider .sw-side-nav-menu {
  background: #373540;
}
.sw-side-nav.ant-layout-sider .sw-side-nav-menu .ant-menu-item-selected {
  background: #28262f;
}
.sw-side-nav.ant-layout-sider .sw-side-nav-menu .ant-menu-sub {
  background: #373540;
  box-shadow: none;
}
.dashboardTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
  color: #272727;
}
.dashboardSubTitle {
  font-size: 16px;
  margin: 0px;
  color: #979797;
}
.dashboardDescription {
  font-size: 12px;
  margin: 0px;
  color: #979797;
}
.filledLightBlue {
  border: none !important;
  background-color: #01cafe !important;
  color: #ffffff !important;
}
.filledLightBlue:hover,
.filledLightBlue:focus {
  border: none !important;
  background-color: #4ebaeb !important;
  color: #ffffff !important;
}
.filledDarkBlue {
  border: none !important;
  background-color: #0197fe !important;
  color: #ffffff !important;
}
.filledDarkBlue:hover,
.filledDarkBlue:focus {
  border: none !important;
  background-color: #0c88dd !important;
  color: #ffffff !important;
}
.ghostGrey {
  border: #c1c1c1 solid 1px !important;
  background-color: #f1f1f1 !important;
  color: #0197fe !important;
}
.ghostGrey:hover,
.ghostGrey:focus {
  border: #0197fe solid 1px !important;
  background-color: #f1f1f1 !important;
  color: #0197fe !important;
}
.ghostWhite {
  border: #c1c1c1 solid 1px !important;
  background-color: #fff !important;
  color: #0197fe !important;
}
.ghostWhite:hover,
.ghosWhite:focus {
  border: #0197fe solid 1px !important;
  background-color: #fff !important;
  color: #0197fe !important;
}
.nudeBlue {
  border: none !important;
  background-color: transparent !important;
  color: #0197fe !important;
  border-radius: 50px;
}
.nudeBlue:hover,
.nudeBlue:focus {
  border: none !important;
  background-color: #e4f4ff !important;
  color: #0197fe !important;
  border-radius: 50px;
}
.nudeGrey {
  border: none !important;
  background-color: transparent !important;
  color: #8d8d8d !important;
  border-radius: 50px;
}
.nudeGrey:hover,
.nudeGrey:focus {
  border: none !important;
  background-color: #f5f5f5 !important;
  color: #8d8d8d !important;
  border-radius: 50px;
}
.ant-btn {
  border-radius: 4px;
}
.ant-affix {
  height: auto !important;
}
.ant-radio-group {
  font-size: 14px;
}
.ant-select-lg,
.ant-input-lg,
.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
}
.ant-form-item-with-help {
  margin-bottom: 8px;
}
.ant-input,
.ant-input-number,
.ant-input-number .ant-input-number-handler-wrap {
  border-radius: 7px;
}
.ant-form-item-control-input .sw-field-auto-complete .ant-select-selector,
.ant-form-item-control-input .ant-select .ant-select-selector,
.ant-form-item-control-input .ant-picker {
  border-radius: 7px !important;
}
.sw-table-tools-top .sw-table-tools-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-group-addon {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-group-addon .ant-select-selection-item {
  text-align: left;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-group-addon .ant-select-selection-item .sw-table-filter-select {
  height: 30px;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-affix-wrapper {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-affix-wrapper .ant-input {
  height: 30px;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-affix-wrapper.sw-table-query {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sw-table-tools-top .sw-table-tools-buttons .ant-input-affix-wrapper.sw-table-query .ant-input {
  height: 30px;
}
.ant-col .ant-cascader .ant-select-selector,
.field-input-antd {
  border-radius: 7px;
}
.ant-tabs-tab .anticon {
  margin-right: 4px;
}
.ant-btn.action-btn-delete {
  color: #0197fe;
  background-color: #fff;
  border-color: #dadbdb;
}
.ant-btn.action-btn-delete:hover {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
r.ant-btn.action-btn-delete:active {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-delete:focus {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-edit {
  color: #0197fe;
  background-color: #fff;
  border-color: #dadbdb;
}
.ant-btn.action-btn-edit:hover {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-edit:active {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-edit:focus {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-create {
  color: #fff;
  background-color: #dadbdb;
  border-color: #dadbdb;
}
.ant-btn.action-btn-create:hover {
  color: #fff;
  background-color: #47acfed9;
  border-color: #47acfed9;
}
.ant-btn.action-btn-create:active {
  color: #fff;
  background-color: #47acfed9;
  border-color: #47acfed9;
}
.ant-btn.action-btn-create:focus {
  color: #fff;
  background-color: #47acfed9;
  border-color: #47acfed9;
}
.ant-btn.action-btn-activate {
  color: #fff;
  background-color: #01cafe;
  border-color: #0ab4df;
}
.ant-btn.action-btn-activate:hover {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-activate:active {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-activate:focus {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-replicate {
  color: #fff;
  background-color: #01cafe;
  border-color: #0ab4df;
}
.ant-btn.action-btn-replicate:hover {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-replicate:active {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-replicate:focus {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-approve {
  color: #fff;
  background-color: #01cafe;
  border-color: #0ab4df;
}
.ant-btn.action-btn-approve:hover {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-approve:active {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-approve:focus {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-pause {
  color: #0197fe;
  background-color: #fff;
  border-color: #dadbdb;
}
.ant-btn.action-btn-pause:hover {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-pause:active {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-pause:focus {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-complete {
  color: #0197fe;
  background-color: #fff;
  border-color: #dadbdb;
}
.ant-btn.action-btn-complete:hover {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-complete:active {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-complete:focus {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-archive {
  color: #fff;
  background-color: #806afb;
  border-color: #806afb;
}
.ant-btn.action-btn-archive:hover {
  color: #fff;
  background-color: #806afb;
  border-color: #806afb;
}
.ant-btn.action-btn-archive:active {
  color: #fff;
  background-color: #806afb;
  border-color: #806afb;
}
.ant-btn.action-btn-archive:focus {
  color: #fff;
  background-color: #806afb;
  border-color: #806afb;
}
.ant-btn.action-btn-submit {
  color: #fff;
  background-color: #01cafe;
  border-color: #0ab4df;
}
.ant-btn.action-btn-submit:hover {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-submit:active {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-submit:focus {
  color: #fff;
  background-color: #4ebaeb;
  border-color: #0ab4df;
}
.ant-btn.action-btn-reject {
  color: #0197fe;
  background-color: #fff;
  border-color: #dadbdb;
}
.ant-btn.action-btn-reject:hover {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-reject:active {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-reject:focus {
  color: #0197fe;
  background-color: #fff;
  border-color: #0197fe;
}
.ant-btn.action-btn-pending_approval:hover {
  color: #e9eba5;
  background-color: #f8fa9e;
  border-color: #d3d60a;
}
.ant-btn.action-btn-pending_approval:active {
  color: #fff;
  background-color: #f8fa9e;
  border-color: #d3d60a;
}
.ant-btn.action-btn-pending_approval:focus {
  color: #e9eba5;
  background-color: #f8fa9e;
  border-color: #d3d60a;
}
.form-page-container .recommendations-tab {
  padding: 24px 24px 0 22px;
}
.form-page-container .recommendations-tab .ant-tabs-extra-content {
  line-height: 15px;
  padding-top: 13px;
}
.form-page-container .recommendations-tab .ant-tabs-extra-content .ant-tabs-new-tab {
  height: 30px;
  width: 30px;
  margin-left: 3px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #01cafe;
  border-radius: 5px;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 55px;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 65px;
  width: 200px;
  line-height: 35px;
  border-radius: 10px;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .tabs-title {
  width: 145px;
  display: inline-table;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .tabs-title .recommendation-index {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  margin: 0;
  height: 18px;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .tabs-title .recommendation-title {
  margin: 0;
  color: black;
  font-size: 13px;
  font-weight: bold;
  height: 29px;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-page-container .recommendations-tab .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  font-size: 15px;
  width: 37px;
  height: 37px;
  padding: 19px 5px 0 0;
}
.form-page-container .recommendations-tab .ant-tabs-bar {
  display: inline;
  border-bottom: none;
}
.form-page-container .recommendations-tab .ant-tabs-nav {
  margin-bottom: 0;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-nav-add {
  color: #01cafe;
  border: 1px solid rgba(1, 202, 254, 0.3);
  border-radius: 5px;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  width: 200px;
  height: 57px;
  border-radius: 10px 10px 0 0;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .recommendation-index {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .recommendation-title {
  color: black;
  font-size: 13px;
  font-weight: bold;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: none;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-remove {
  font-size: 15px;
}
.form-page-container .recommendations-tab .ant-tabs-nav .ant-tabs-nav-operations .ant-tabs-nav-add {
  color: #01cafe;
  border: 1px solid rgba(1, 202, 254, 0.3);
  border-radius: 5px;
}
.form-page-container .page-content {
  padding: 0px 24px 0 22px;
}
.form-page-container .page-content .layout-preview-card .ant-card-meta-avatar .icon-preview {
  width: 50px;
  height: 50px;
}
.form-page-container .page-content .recommendations-form {
  padding-top: 30px;
}
.form-page-container .page-content .layout-preview-card .ant-card-meta {
  margin: 5px 15px 0 5px;
}
.form-page-container .page-content .recommendation-form-content {
  margin: 0 0 0 1px;
}
.form-page-container .page-content .translation-form {
  display: block;
  padding-top: 25px;
}
.form-page-container .page-content .action-form {
  padding-top: 35px;
}
.form-page-container .page-content .country-form {
  display: block;
  padding-top: 15px;
}
.form-page-container .page-content .os-version-range {
  padding-top: 15px;
}
.form-page-container .page-content .no-campaign {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin: 100px 30% 50px 30%;
  padding: 50px 0 50px 0;
  font-size: large;
  text-align: center;
  background-color: white;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu .recommendation-index {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu .recommendation-title {
  color: black;
  font-size: 13px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: none;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item-remove {
  font-size: 15px;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: white !important;
  border-width: 2px;
  color: black;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #d2dadc !important;
  border-color: transparent;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon .i.anticon.anticon-plus {
  color: black !important;
  display: block;
  margin-top: 7px;
}
.i.anticon.anticon-plus {
  color: blue !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: black;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: transparent;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-item-description {
  font-size: 12px;
}
.ant-form .ant-form-item-extra,
.ant-form .ant-form-item-explain-error,
.ant-form .has-error .ant-form-explain,
.ant-form .has-error .ant-form-split {
  font-size: 12px;
  margin-top: 2px;
}
.ant-card-body {
  height: 100%;
}
.mPageHeader .sw-page-header-detail {
  padding: 14px 24px;
  min-height: 60px;
}
.mPageHeader .sw-page-header-detail .sw-page-detail-container {
  flex: 0 1 auto;
  width: 100%;
}
.header-action-container {
  text-align: right;
}
.new-campaign {
  margin: 24px 24px 0;
}
.stepTitle {
  font-family: 'Samsung Sharp Sans Bold';
  margin-top: 16px;
}
.showPreviewCard {
  width: 120px;
  position: absolute;
  right: 0;
  top: 0;
}
.showPreviewCard .ant-card-head {
  color: white;
  background-color: #373540;
  text-align: center;
  font-size: 12px;
}
.showPreviewIcon {
  width: 80px;
  height: 80px;
  color: white;
  margin: auto;
  text-align: center;
  background-color: dimgrey;
  font-size: 80px;
  border-radius: 10px;
}
.showPreviewIcon .anticon {
  display: block;
}
.layout-preview-card {
  max-width: 456px;
  height: 166px;
  border-radius: 26px;
  margin-top: 0;
}
.layout-preview-card .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: -24px;
}
.page-content .layout-preview-card .ant-card-meta-avatar .icon-preview {
  width: 50px;
  height: 50px;
}
.layout-preview-card .ant-avatar.image-preview {
  width: 90px;
  height: 65px;
}
.layout-preview-card .text-menu {
  margin: 10px 15px auto 0;
  font-size: 16px;
  float: right;
  color: #fff;
}
.layout-preview-card .view-detail {
  margin: 10px 15px 0 0;
  font-size: 16px;
  float: right;
  color: #fff;
}
.layout-preview-card .ant-card-body {
  padding: 18px;
}
.layout-preview-card .meta-content-container {
  height: 88px;
  display: flex;
  align-items: center;
}
.layout-preview-card .subtitle-font-style {
  font-size: 12px;
}
.layout-preview-card .bg-image-text {
  height: 118px;
  display: flex;
  align-items: center;
}
.recommendation-form-content .ant-affix {
  right: 48px;
}
.preview-modal-recom {
  width: 400px;
}
.preview-modal-recom .ant-card-head,
.preview-modal-recom .ant-card-extra {
  background-color: #373540;
  color: white;
}
.formExtra {
  bottom: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
}
.template-btn {
  font-size: 10px;
  text-align: left;
}
.translation-form-container,
.ant-card.translation-form-container {
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}
.translation-form-container.ant-card-bordered.error,
.ant-card.translation-form-container.ant-card-bordered.error {
  border: 1px solid #f5222d;
}
.translation-form-container.ant-card-bordered.hidden,
.ant-card.translation-form-container.ant-card-bordered.hidden {
  border: none;
  margin-top: 0;
  margin-bottom: 0;
}
.translation-form-container .ant-card-body,
.ant-card.translation-form-container .ant-card-body {
  padding: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
}
.translation-form-container.extra-height .translation-text-row,
.ant-card.translation-form-container.extra-height .translation-text-row {
  height: 304px;
}
.translation-form-container .translation-text-row,
.ant-card.translation-form-container .translation-text-row {
  height: 204px;
  overflow-y: auto;
}
.translation-form-container .translation-text-row:not(:last-child),
.ant-card.translation-form-container .translation-text-row:not(:last-child) {
  padding-bottom: 16px;
}
.translation-form-container .translation-text-row .translation-field-container,
.ant-card.translation-form-container .translation-text-row .translation-field-container {
  padding: 22px 14px 0 0;
}
.translation-form-container .translation-text-row .translation-field-container .ant-form-item-control,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-form-item-control {
  min-height: 54px;
}
.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 8px;
}
.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item .ant-input-group,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item .ant-input-group {
  margin-bottom: 4px;
}
.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item input.ant-input,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item input.ant-input {
  margin-bottom: -1px;
}
.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item.ant-form-item-with-help,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 0;
}
.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item.ant-form-item-with-help .ant-form-explain,
.ant-card.translation-form-container .translation-text-row .translation-field-container .ant-row.ant-form-item.ant-form-item-with-help .ant-form-explain {
  margin-top: -4px;
  margin-bottom: 0;
}
.translation-form-container .translation-text-row .anticon-delete,
.ant-card.translation-form-container .translation-text-row .anticon-delete {
  padding-right: 10px;
  cursor: pointer;
  color: #f5222d;
}
.language-list-container {
  width: 95%;
  overflow-y: auto;
  height: 204px;
  border-right: 1px solid #e8e8e8;
}
.language-list-container.extra-height {
  height: 304px;
}
.language-list-container.ant-card-bordered.error {
  border: 1px solid #f5222d;
}
.language-list-container .list {
  padding: 0;
  margin: 0;
}
.language-list-container .list .list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 56px;
  border-bottom: 1px solid #e8e8e8;
}
.language-list-container .list .list-item:hover {
  background: #f9f9f9;
}
.language-list-container .list .list-item.active,
.language-list-container .list .list-item:hover.active {
  background: #e8e8e8;
}
.language-list-container .list .list-item.error {
  background: #f9ced0;
}
.language-list-container .list .list-item.error:hover {
  background: #fb9597b3;
}
.language-list-container .list .list-item.error.active,
.language-list-container .list .list-item.error:hover.active {
  background: #fb9597b3;
}
.language-list-container .list .list-item .language-name {
  width: 80%;
}
.language-list-container .list .list-item .action-container {
  width: 20%;
  text-align: right;
}
.language-list-container .list .list-item .action-container .anticon-delete {
  color: #f5222d;
}
.translation-text-row {
  height: 204px;
  overflow-y: auto;
}
.translation-field-container {
  padding: 10px 10px 10px 40px;
}
.translation-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 8px;
}
.translation-field-container .ant-form-item-control {
  min-height: 54px;
}
.ant-card.country-form-container,
.country-form-container {
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  overflow-y: auto;
}
.ant-card.country-form-container.ant-card-bordered.error,
.country-form-container.ant-card-bordered.error {
  border: 1px solid #f5222d;
}
.ant-card.country-form-container.ant-card-bordered.hidden,
.country-form-container.ant-card-bordered.hidden {
  border: none;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card.country-form-container .ant-card-body,
.country-form-container .ant-card-body {
  padding: 0;
}
.ant-card.country-form-container.extra-height .country-text-row,
.country-form-container.extra-height .country-text-row {
  height: 304px;
}
.ant-card.country-form-container.extra-height grammarly-extension,
.country-form-container.extra-height grammarly-extension {
  display: none;
}
.ant-card.country-form-container .country-text-row,
.country-form-container .country-text-row {
  height: 204px;
  overflow-y: auto;
}
.ant-card.country-form-container .country-text-row:not(:last-child),
.country-form-container .country-text-row:not(:last-child) {
  padding-bottom: 16px;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-form-item-control,
.country-form-container .country-text-row .country-field-container .ant-form-item-control {
  min-height: 54px;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item,
.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 8px;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item .ant-input-group,
.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item .ant-input-group {
  margin-bottom: 4px;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item input.ant-input,
.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item input.ant-input {
  margin-bottom: -1px;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item.ant-form-item-with-help,
.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-card.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item.ant-form-item-with-help .ant-form-explain,
.country-form-container .country-text-row .country-field-container .ant-row.ant-form-item.ant-form-item-with-help .ant-form-explain {
  margin-top: -4px;
  margin-bottom: 0;
}
.ant-card.country-form-container .country-text-row .anticon-delete,
.country-form-container .country-text-row .anticon-delete {
  padding-right: 10px;
  cursor: pointer;
  color: #f5222d;
}
.country-form-container .ant-card-body {
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  padding: 0px;
}
.country-list-container {
  width: 100%;
  overflow-y: auto;
  height: 304px;
  border-right: 1px solid #e8e8e8;
}
.country-list-container .list {
  padding: 0;
  margin: 0;
}
.country-list-container .list .list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 56px;
  border-bottom: 1px solid #e8e8e8;
}
.country-list-container .list .list-item:hover {
  background: #f9f9f9;
}
.country-list-container .list .list-item.active,
.country-list-container .list .list-item:hover.active {
  background: #e8e8e8;
}
.country-list-container .list .list-item.error {
  background: #f9ced0;
}
.country-list-container .list .list-item.error:hover {
  background: #fb9597b3;
}
.country-list-container .list .list-item.error.active,
.country-list-container .list .list-item.error:hover.active {
  background: #fb9597b3;
}
.country-list-container .list .list-item.error.info .country-name {
  width: 75%;
}
.country-list-container .list .list-item.error.info .action-container {
  width: 35%;
}
.country-list-container .list .list-item.error.info .action-container .anticon:not(:last-child) {
  padding-right: 3px;
}
.country-list-container .list .list-item .country-name {
  width: 80%;
}
.country-list-container .list .list-item .action-container {
  width: 20%;
  text-align: right;
}
.country-list-container .list .list-item .action-container .anticon-delete {
  color: #f5222d;
}
.country-text-row {
  height: 204px;
  overflow-y: auto;
}
.country-field-container {
  padding: 10px 10px 10px 40px;
}
.country-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 0px;
}
.country-field-container .ant-form-item-control {
  min-height: 54px;
}
.submitRecBtn button {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin: 0px 5px;
  float: right;
}
.submitRecBtn .ant-btn:hover,
.submitRecBtn .ant-btn:focus,
.submitRecBtn .ant-btn:active,
.submitRecBtn .ant-btn.active {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin: 0px 5px;
  float: right;
}
.ant-input[disabled] {
  background: #eaeaea;
  height: 42px;
}
.ant-input {
  height: 42px;
}
.ant-time-picker {
  width: 180px !important;
}
.ant-input .sw-form-field .sw-field-input {
  height: 42px;
}
.ant-input.sw-field-input {
  border-radius: 7px;
  height: 42px;
}
.sw-field-select {
  border-radius: 7px;
}
.sw-field-select .ant-select-selection {
  height: 42px;
  border-radius: 7px;
}
.sw-field-select .ant-select-selection .ant-select-selection__rendered {
  line-height: 40px;
}
.sw-field-textarea {
  border-radius: 7px;
  height: 60px;
}
form textarea.ant-input {
  height: 60px;
}
.form-page-container .form-buttons {
  display: block;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 50px;
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 14px;
  letter-spacing: 1.45px;
}
.form-page-container .form-buttons.top {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0;
}
.form-page-container .form-buttons .discard-all-text {
  color: #0197fe;
  font-size: inherit;
  letter-spacing: inherit;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.form-page-container .form-buttons .discard-all-text:hover {
  color: #0197fe;
  font-size: inherit;
  letter-spacing: inherit;
  background-color: #d8eaef;
  padding: 10px;
  border-radius: 6px;
}
.form-page-container .form-buttons .next-button {
  margin-left: 12px;
  font-size: inherit;
  background-color: #01cafe;
  color: #fff;
  border: none;
  letter-spacing: inherit;
  border-radius: 6px;
}
.form-page-container .form-buttons .next-button:hover {
  margin-left: 12px;
  font-size: inherit;
  background-color: #4ebaeb;
  color: #fff;
  border: none;
  letter-spacing: inherit;
  border-radius: 6px;
}
.form-page-container .form-buttons .previous-button {
  margin-right: 12px;
  font-size: inherit;
  color: #0197fe;
  border-color: #c1c1c1;
  background-color: #fff;
  letter-spacing: inherit;
  border-radius: 6px;
}
.form-page-container .form-buttons .previous-button:hover {
  margin-right: 12px;
  font-size: inherit;
  color: #0197fe;
  border-color: #0197fe;
  background-color: #fff;
  letter-spacing: inherit;
  border-radius: 6px;
}
.form-page-container .form-buttons .save-button {
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: 6px;
  background-color: #0197fe;
  color: #fff;
  border: none;
}
.form-page-container .form-buttons .save-button:hover {
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: 6px;
  background-color: #0c88dd;
  color: #fff;
  border: none;
}
.form-page-container .form-buttons .cancel-button {
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: 6px;
  background-color: #fff;
  color: #0197fe;
  border: none;
}
.form-page-container .form-buttons .cancel-button:hover {
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: 6px;
  background-color: #eeeded;
  color: #0c88dd;
  border: none;
}
.campaign-form .ant-picker.sw-form-field.sw-field-date-picker {
  width: 100%;
}
.ab-form-container .ab-form-card {
  border: 0px;
}
.ab-form-container .ab-form-card .ant-card-body {
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
}
.ab-form-container .ab-form-card.hidden .ant-card-body {
  opacity: 0;
  padding: 0;
  overflow: hidden;
  height: 0;
}
.ab-form-container .ab-form-card .ant-card-head .ant-card-head-title {
  color: black;
}
.ab-form-container .ab-form-card .ant-card-head .ant-card-extra {
  width: 100%;
  padding: 0;
  height: 60px;
}
.ab-form-container .ab-form-card .ant-card-head .ant-card-extra .card-title-custom {
  height: 60px;
  font-size: 18px;
  padding: 15px 0 18px 0;
  font-weight: 'bold';
  color: black;
}
.ab-form-container .ab-form-weight-container {
  border: 1px solid #e8e8e800;
  border-radius: 3px;
}
.ab-form-container .ab-form-weight-container.error {
  border-color: #f5222d;
}
.ab-form-container .ab-form-weight-container .ab-form-weight-row {
  padding: 8px;
}
.ab-form-container .ab-form-weight-container .ab-form-weight-row .ab-form-weight-input {
  text-align: right;
}
.ab-form-container .sw-form-actions .sw-col-action:nth-child(2) {
  padding: 0 32px 8px 32px;
}
.ab-form-container .algorithm-radio .ant-radio-wrapper {
  display: block;
  margin-bottom: 10px;
}
.ab-form-container .algorithm-radio .ant-radio-wrapper:last-child {
  margin-bottom: 0px;
}
.ab-form-container .goal-field.sw-field-textarea {
  max-width: 70%;
}
@media screen and (max-width: 850px) {
  .ab-form-container .goal-field.sw-field-textarea {
    max-width: 100%;
  }
}
.ab-form-container .percent {
  font-family: 'Samsung Sharp Sans Medium';
  font-size: 15px;
  color: #979797;
  margin-left: 10px;
}
.ab-form-container .weight-form-info {
  padding-left: 8px;
}
.ab-form-container .weight-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}
.form-page-container .page-content .ant-input {
  height: 42px;
  border-radius: 8px;
  padding-top: 7px;
}
.form-page-container .page-content .ant-upload {
  width: 100%;
}
.form-page-container .page-content .add-locale-btn {
  height: 42px;
  border-radius: 8px;
  width: 100%;
}
.form-page-container .page-content .csv-uploader-btn {
  color: #0197fe;
  border-radius: 8px;
  height: 42px;
  width: 100%;
}
.form-page-container .page-content .template-btn {
  font-size: 12px;
  text-align: left;
}
.form-page-container .page-content .color-code {
  padding-left: 15px;
}
.form-page-container .page-content .translation-or {
  padding: 10px 33% 0 33%;
  align-items: center;
}
.form-page-container .status-tag {
  float: right;
  text-align: center;
}
.form-page-container .status-tag .status-specific {
  font-size: 10px;
}
.form-page-container .status-tag .tag-label {
  width: 90px;
  height: 25px;
}
.form-page-container .step-menu-row {
  margin: 24px 24px 0 24px;
}
.form-page-container .step-menu-row .step-menu {
  width: 400px;
  float: right;
}
.form-page-container .step-menu-row .step-menu .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.form-page-container .new-campaign {
  margin: 20px 24px 0 24px;
}
.form-page-container .new-campaign .header-action-container {
  text-align: right;
}
.form-page-container .new-campaign .header-action-container button[class^='ant-btn action-btn'],
.form-page-container .new-campaign .header-action-container button[class^='ant-btn ant-btn-primary action-btn'] {
  min-width: 126px;
  border-radius: 8px;
}
.form-page-container .new-campaign .header-action-container button[class^='ant-btn action-btn']:not(:first-child),
.form-page-container .new-campaign .header-action-container button[class^='ant-btn ant-btn-primary action-btn']:not(:first-child) {
  margin-left: 16px;
}
.form-page-container .new-campaign .header-action-container button[class^='ant-btn action-btn'] i,
.form-page-container .new-campaign .header-action-container button[class^='ant-btn ant-btn-primary action-btn'] i {
  margin-right: 8px;
}
.form-page-container .form-switch {
  background-color: #01cafe;
}
.ant-form-item-no-colon.ant-form-item-required::before,
.ant-form-item-required::before {
  display: none !important;
}
.ant-form-item-label {
  text-align: left !important;
  white-space: normal;
}
.ant-form-item-label > label {
  display: inline-table;
}
.ant-form-item-label > label.ant-form-item-no-colon.ant-form-item-required::after,
.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-modal.modal-dialog .ant-modal-content {
  width: 493px;
  height: auto;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body {
  font-size: 14px;
  text-align: justify;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .anticon {
  float: left;
  font-size: 17px;
  margin-right: 7px;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .anticon.anticon-loading {
  color: #1890ff;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .dialog-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .dialog-description {
  display: block;
  margin-left: 36px;
  margin-top: 12px;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .info-title:not(:first-child) {
  padding-top: 12px;
  display: inline-block;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .info-details {
  font-size: 14px;
  line-height: 1.2;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-body .info-details .ant-tag {
  margin-top: 4px;
  margin-right: 4px;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.delete,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.reject,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.discard {
  color: #ec3c3e;
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.delete:hover,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.reject:hover,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.discard:hover {
  color: #fff;
  background-color: #d9363e;
  border-color: #ff7875;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.delete:active,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.reject:active,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.discard:active {
  color: #fff;
  background-color: #d9363e;
  border-color: #d9363e;
}
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.delete:focus,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.reject:focus,
.ant-modal.modal-dialog .ant-modal-content .ant-modal-footer .ant-btn.discard:focus {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.ant-input-group-wrapper .ant-input-search-button {
  height: 42px;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
  width: 150px;
}
.sw-page-header-detail {
  padding: 0px 0px !important;
}
.sw-table-tool-actions {
  display: contents;
}
.sw-row-action-cell {
  color: #3057a5;
  font-weight: bold;
}
.createNewButton {
  background-color: #01cafe;
  border: none;
}
.searchDiv {
  text-align: right;
  height: 34px;
}
.tableToolsIcon {
  padding-top: 10px;
}
.campaignListButton {
  color: #0197fe;
  background-color: #f1f1f1;
  border: 1px solid #c1c1c1;
  margin-left: 10px;
}
.campaignListButton:hover {
  color: #0197fe;
  background-color: #f1f1f1;
  border: 1px solid #0197fe;
  margin-left: 10px;
}
.statusButton {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: #8d8d8d;
}
.statusButtonActive {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: white;
  background-color: black;
}
.statusButtonActive:focus {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: white;
  background-color: black;
}
.cornerLeft {
  border-radius: 10px 0px 0px 10px !important;
}
.cornerRight {
  border-radius: 0px 10px 10px 0px !important;
}
.smallFont {
  font-size: 8px;
  margin: 0;
}
.ant-table .layout-preview-card {
  font-size: 8px;
  width: 200px;
  max-width: 200px !important;
  height: 93px !important;
  border-radius: 12px !important;
  margin-top: 0;
  padding: 0;
}
.ant-table .ant-card-meta-avatar {
  float: left;
  width: 20px !important;
  height: 20px !important;
  padding-right: 8px !important;
}
.ant-table .layout-preview-card .ant-card-meta {
  margin: 5px !important;
}
.ant-table .layout-preview-card .ant-avatar.image-preview,
.ant-table .layout-preview-card .ant-avatar.icon-preview {
  width: 32px !important;
  height: 32px !important;
}
.ant-table .layout-preview-card .view-detail {
  margin: 0px 5px 5px 0 !important;
  font-size: 8px !important;
  float: right;
  color: #fff;
}
.layout-preview-card .ant-card-body {
  padding: 18px;
}
.ant-table .layout-preview-card .meta-content-container {
  height: 52px !important;
  display: flex;
  align-items: center;
}
.ant-table .layout-preview-card .subtitle-font-style {
  font-size: 6px !important;
}
.ant-table .layout-preview-card .bg-image-text {
  height: 69px !important;
  display: flex;
  align-items: center;
}
.static-recommendation-table-tool .sw-table-info {
  visibility: hidden;
  display: none;
}
.static-recommendation-table-tool .ant-divider-horizontal {
  display: none;
}
.rec-row {
  min-height: 90px;
}
.rec-row .ant-card {
  background-color: #fafafa;
}
.rec-row .ant-card .ant-card-body {
  padding: 10px 5px 5px 5px;
}
.rec-row .p {
  margin: auto;
}
.rec-row .preview-recommendation {
  display: flex;
  padding-bottom: 10px;
}
.rec-row .preview-recommendation .ant-card-body {
  padding: 7px 10px 5px 5px;
}
.rec-row .preview-recommendation .icon-text-tmplt {
  display: block;
}
.rec-row .preview-recommendation .icon-text-tmplt .ant-card-meta-avatar {
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.rec-row .preview-recommendation .icon-text-tmplt .ant-card-meta-detail {
  padding: 5px 0 0 20px;
}
.rec-row .preview-recommendation .image-text-tmplt {
  display: block;
}
.rec-row .preview-recommendation .image-text-tmplt .ant-card-meta-avatar {
  margin-top: 5px;
}
.rec-row .preview-recommendation .image-text-tmplt .ant-card-meta-detail {
  padding: 5px 0 0 20px;
}
.rec-row .preview-recommendation .only-text-tmplt {
  display: block;
}
.rec-row .preview-recommendation .only-text-tmplt .ant-card-meta-detail {
  padding: 8px 0 0 0px;
}
.rec-row .preview-recommendation .only-text-tmplt .view-details {
  padding-top: 35px;
}
.rec-row .preview-recommendation .view-detail {
  margin: 0 5px 5px 0 !important;
  font-size: 8px !important;
  float: right;
  color: #fff;
}
.rec-row .preview-recommendation .bg-image-text {
  padding: 0;
}
.hide-reload .sw-table-tools-buttons .anticon-reload {
  visibility: hidden;
  width: 0px;
}
.ant-dropdown-menu-item-active {
  color: #0197fe;
}
.ant-dropdown-menu-item-active a {
  color: #0197fe;
}
.ant-dropdown-menu-item a span {
  padding-left: 4px;
}
.ant-tag {
  margin: 0px;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.ant-table-filter-dropdown {
  left: 180px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-left: 30px;
  padding-right: 10px !important;
}
