// .ab-form-container{
//   .goal-field.sw-field-textarea {
//     max-width: 70%;
//   }
//   .abtype-switch {
//     background-color: rgb(1, 202, 254);
//   }
//   .ab-form-weight-row{

//   }

// }

.ab-form-container {
  .ab-form-card {
    border: 0px;
    .ant-card-body {
      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      height: 100%;
    }
    &.hidden {
      .ant-card-body {
        opacity: 0;
        padding: 0;
        overflow: hidden;
        height: 0;
      }
    }
    .ant-card-head {
      .ant-card-head-title {
        color: black;
      }
      .ant-card-extra {
        width: 100%;
        padding: 0;
        height: 60px;
        .card-title-custom {
          height: 60px;
          font-size: 18px;
          padding: 15px 0 18px 0;
          font-weight: 'bold';
          color: black;
        }
      }
    }
  }
  .ab-form-weight-container {
    border: 1px solid #e8e8e800;
    &.error {
      border-color: @error-color;
    }
    border-radius: 3px;
    .ab-form-weight-row {
      padding: 8px;
      .ab-form-weight-input {
        text-align: right;
      }
    }
  }
  .sw-form-actions {
    .sw-col-action:nth-child(2) {
      padding: 0 32px 8px 32px;
    }
  }
  .algorithm-radio {
    .ant-radio-wrapper {
      display: block;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .goal-field.sw-field-textarea {
    max-width: 70%;
    @media screen and (max-width: 850px) {
      max-width: 100%;
    }
  }

  .percent {
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 15px;
    color: #979797;
    margin-left: 10px;
  }
  .weight-form-info {
    padding-left: 8px;
  }

  .weight-label {
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
  }
}
