.ant-steps-item-process .ant-steps-item-icon {
  background-color: white !important;
  border-width: 2px;
  color: black;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #d2dadc !important;
  border-color: transparent;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-wait
  .ant-steps-item-icon
  .ant-steps-icon
  .i.anticon.anticon-plus {
  color: black !important;
  display: block;
  margin-top: 7px;
}
.i.anticon.anticon-plus {
  color: blue !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: black;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: transparent;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-item-description {
  font-size: 12px;
}

.ant-form {
  .ant-form-item-extra,
  .ant-form-item-explain-error,
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    font-size: 12px;
    margin-top: 2px;
  }
}

.ant-card-body {
  height: 100%;
}

.mPageHeader .sw-page-header-detail {
  padding: 14px 24px;
  min-height: 60px;
}
.mPageHeader .sw-page-header-detail .sw-page-detail-container {
  flex: 0 1 auto;
  width: 100%;
}
.header-action-container {
  text-align: right;
}
.new-campaign {
  margin: 24px 24px 0;
}

.stepTitle {
  font-family: 'Samsung Sharp Sans Bold';
  margin-top: 16px;
}

.showPreviewCard {
  width: 120px;
  position: absolute;
  right: 0;
  top: 0;
}
.showPreviewCard .ant-card-head {
  color: white;
  background-color: #373540;
  text-align: center;
  font-size: 12px;
}
.showPreviewIcon {
  width: 80px;
  height: 80px;
  color: white;
  margin: auto;
  text-align: center;
  background-color: dimgrey;
  font-size: 80px;
  border-radius: 10px;
}
.showPreviewIcon .anticon {
  display: block;
}
.layout-preview-card {
  max-width: 456px;
  height: 166px;
  border-radius: 26px;
  margin-top: 0;
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: -24px;
  }
}

.page-content {
  .layout-preview-card .ant-card-meta-avatar {
    .icon-preview {
      width: 50px;
      height: 50px;
    }
  }
}
.layout-preview-card .ant-avatar.image-preview {
  width: 90px;
  height: 65px;
}
.layout-preview-card .text-menu {
  margin: 10px 15px auto 0;
  font-size: 16px;
  float: right;
  color: #fff;
}

.layout-preview-card .view-detail {
  margin: 10px 15px 0 0;
  font-size: 16px;
  float: right;
  color: #fff;
}
.layout-preview-card .ant-card-body {
  padding: 18px;
}

.layout-preview-card .meta-content-container {
  height: 88px;
  display: flex;
  align-items: center;
}

.layout-preview-card .subtitle-font-style {
  font-size: 12px;
}

.layout-preview-card .bg-image-text {
  height: 118px;
  display: flex;
  align-items: center;
}

.recommendation-form-content .ant-affix {
  right: 48px;
}

.preview-modal-recom {
  width: 400px;
  // left: 75px;
}
.preview-modal-recom .ant-card-head,
.preview-modal-recom .ant-card-extra {
  background-color: #373540;
  color: white;
}
.formExtra {
  bottom: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
}
.template-btn {
  font-size: 10px;
  text-align: left;
}

.translation-form-container,
.ant-card.translation-form-container {
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  &.ant-card-bordered.error {
    border: 1px solid #f5222d;
  }
  &.ant-card-bordered.hidden {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-card-body {
    padding: 0;
    -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 100%;
  }
  &.extra-height {
    .translation-text-row {
      height: 304px;
    }
  }
  .translation-text-row {
    height: 204px;
    overflow-y: auto;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
    // .scroll-bar {
    //   div:nth-child(1) {
    //     background-color: transparent !important;
    //   }
    // }
    .translation-field-container {
      padding: 22px 14px 0 0;
      .ant-form-item-control {
        min-height: 54px;
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
        min-height: 54px;
        padding-bottom: 8px;
        .ant-input-group {
          margin-bottom: 4px;
        }
        input.ant-input {
          margin-bottom: -1px;
        }
        &.ant-form-item-with-help {
          margin-bottom: 0;
          .ant-form-explain {
            margin-top: -4px;
            margin-bottom: 0;
          }
        }
      }
    }
    .anticon-delete {
      padding-right: 10px;
      cursor: pointer;
      color: @error-color;
    }
  }
}

.language-list-container {
  width: 95%;
  overflow-y: auto;
  &.extra-height {
    height: 304px;
  }
  height: 204px;
  border-right: 1px solid #e8e8e8;
  &.ant-card-bordered.error {
    border: 1px solid #f5222d;
  }
  .list {
    padding: 0;
    margin: 0;
    .list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 56px;
      border-bottom: 1px solid #e8e8e8;
      &:hover {
        background: #f9f9f9;
      }
      &.active,
      &:hover.active {
        background: #e8e8e8;
      }
      &.error {
        background: @error-color-lighter;
        &:hover {
          background: @error-color-light;
        }
        &.active,
        &:hover.active {
          background: @error-color-light;
        }
      }
      .language-name {
        width: 80%;
      }
      .action-container {
        width: 20%;
        text-align: right;
        .anticon-delete {
          color: @error-color;
        }
      }
    }
  }
}

.translation-text-row {
  height: 204px;
  overflow-y: auto;
}
.translation-field-container {
  padding: 10px 10px 10px 40px;
}
.translation-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 8px;
}
.translation-field-container .ant-form-item-control {
  min-height: 54px;
}

.ant-card.country-form-container,
.country-form-container {
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  overflow-y: auto;
  &.ant-card-bordered.error {
    border: 1px solid #f5222d;
  }
  &.ant-card-bordered.hidden {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-card-body {
    padding: 0;
  }
  &.extra-height {
    .country-text-row {
      height: 304px;
    }
    // disable grammarly extension for JSON field data
    grammarly-extension {
      display: none;
    }
  }
  .country-text-row {
    height: 204px;
    overflow-y: auto;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
    .country-field-container {
      .ant-form-item-control {
        min-height: 54px;
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
        min-height: 54px;
        padding-bottom: 8px;
        .ant-input-group {
          margin-bottom: 4px;
        }
        input.ant-input {
          margin-bottom: -1px;
        }
        &.ant-form-item-with-help {
          margin-bottom: 0;
          .ant-form-explain {
            margin-top: -4px;
            margin-bottom: 0;
          }
        }
      }
    }
    .anticon-delete {
      padding-right: 10px;
      cursor: pointer;
      color: @error-color;
    }
  }
}
.country-form-container .ant-card-body {
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  padding: 0px;
}
.country-list-container {
  width: 100%;
  overflow-y: auto;
  height: 304px;
  border-right: 1px solid #e8e8e8;
  .list {
    padding: 0;
    margin: 0;
    .list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 56px;
      border-bottom: 1px solid #e8e8e8;
      &:hover {
        background: #f9f9f9;
      }
      &.active,
      &:hover.active {
        background: #e8e8e8;
      }
      &.error {
        background: @error-color-lighter;
        &:hover {
          background: @error-color-light;
        }
        &.active,
        &:hover.active {
          background: @error-color-light;
        }
      }
      &.error.info {
        .country-name {
          width: 75%;
        }
        .action-container {
          width: 35%;
          .anticon:not(:last-child) {
            padding-right: 3px;
          }
        }
      }
      .country-name {
        width: 80%;
      }
      .action-container {
        width: 20%;
        text-align: right;
        .anticon-delete {
          color: @error-color;
        }
      }
    }
  }
}

.country-text-row {
  height: 204px;
  overflow-y: auto;
}
.country-field-container {
  padding: 10px 10px 10px 40px;
}
.country-field-container .ant-row.ant-form-item {
  margin-bottom: 0;
  min-height: 54px;
  padding-bottom: 0px;
}
.country-field-container .ant-form-item-control {
  min-height: 54px;
}

.submitRecBtn button {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin: 0px 5px;
  float: right;
}
.submitRecBtn .ant-btn:hover,
.submitRecBtn .ant-btn:focus,
.submitRecBtn .ant-btn:active,
.submitRecBtn .ant-btn.active {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin: 0px 5px;
  float: right;
}

//Overwrite SWUIF form
.ant-input[disabled] {
  background: #eaeaea;
  height: 42px;
}

.ant-input {
  height: 42px;
}
.ant-time-picker {
  width: 180px !important;
}
.ant-time-picker-input {
  // background: #fafafa !important;
}
.form-page-container .page-content .ant-input {
  // background: #fafafa;
}
.ant-input .sw-form-field .sw-field-input {
  height: 42px;
  // background: #fafafa;
}
.ant-input.sw-field-input {
  border-radius: 7px;
  // background: #fafafa;
  height: 42px;
}
.sw-field-select {
  border-radius: 7px;
  .ant-select-selection {
    height: 42px;
    border-radius: 7px;
    // background: #fafafa;
    .ant-select-selection__rendered {
      line-height: 40px;
    }
  }
}

.sw-field-textarea {
  border-radius: 7px;
  height: 60px;
  // background: #fafafa;
}
form textarea.ant-input {
  height: 60px;
  // margin-bottom: 4px;
}

.form-page-container {
  .form-buttons {
    &.top {
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 0;
    }
    display: block;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 50px;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 14px;
    letter-spacing: 1.45px;
    .discard-all-text {
      color: #0197fe;
      font-size: inherit;
      letter-spacing: inherit;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;
    }
    .discard-all-text:hover {
      color: #0197fe;
      font-size: inherit;
      letter-spacing: inherit;
      background-color: #d8eaef;
      padding: 10px;
      border-radius: 6px;
    }

    .next-button {
      margin-left: 12px;
      font-size: inherit;
      background-color: #01cafe;
      color: #fff;
      border: none;
      letter-spacing: inherit;
      border-radius: 6px;
    }
    .next-button:hover {
      margin-left: 12px;
      font-size: inherit;
      background-color: #4ebaeb;
      color: #fff;
      border: none;
      letter-spacing: inherit;
      border-radius: 6px;
    }

    .previous-button {
      margin-right: 12px;
      font-size: inherit;
      color: #0197fe;
      border-color: #c1c1c1;
      background-color: #fff;
      letter-spacing: inherit;
      border-radius: 6px;
    }

    .previous-button:hover {
      margin-right: 12px;
      font-size: inherit;
      color: #0197fe;
      border-color: #0197fe;
      background-color: #fff;
      letter-spacing: inherit;
      border-radius: 6px;
    }

    .save-button {
      font-size: inherit;
      letter-spacing: inherit;
      border-radius: 6px;
      background-color: #0197fe;
      color: #fff;
      border: none;
    }
    .save-button:hover {
      font-size: inherit;
      letter-spacing: inherit;
      border-radius: 6px;
      background-color: #0c88dd;
      color: #fff;
      border: none;
    }
    .cancel-button {
      font-size: inherit;
      letter-spacing: inherit;
      border-radius: 6px;
      background-color: #fff;
      color: #0197fe;
      border: none;
    }
    .cancel-button:hover {
      font-size: inherit;
      letter-spacing: inherit;
      border-radius: 6px;
      background-color: rgb(238, 237, 237);
      color: #0c88dd;
      border: none;
    }
  }
}

.campaign-form {
  .ant-picker.sw-form-field.sw-field-date-picker {
    width: 100%;
  }
}
