// .ant-input-affix-wrapper {
//   width: 40%;
// }
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.ant-checkbox-inner {
  // border-color: #6fd2ff;
  // background-color: #e2f7ff;
}
.ant-input-group-wrapper {
  // max-width: 500px !important;
}

.ant-input-group-wrapper .ant-input-search-button {
  height: 42px;
}

.ant-input-group-addon .ant-select {
  margin: -5px -11px;
  width: 150px;
}
.sw-page-header-detail {
  padding: 0px 0px !important;
}
.sw-table-tool-actions {
  // width: 100%;
  display: contents;
}
// .sw-table-tools {
//   .sw-table-info {
//     visibility: inherit;
//     display: block;
//   }
// }
.sw-row-action-cell {
  color: #3057a5;
  font-weight: bold;
}
.createNewButton {
  background-color: #01cafe;
  border: none;
}
.searchDiv {
  text-align: right;
  height: 34px;
}
.tableToolsIcon {
  padding-top: 10px;
}
.campaignListButton {
  color: #0197fe;
  background-color: #f1f1f1;
  border: 1px solid #c1c1c1;
  margin-left: 10px;
}
.campaignListButton:hover {
  color: #0197fe;
  background-color: #f1f1f1;
  border: 1px solid #0197fe;
  margin-left: 10px;
}

.statusButton {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: #8d8d8d;
}
.statusButtonActive {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: white;
  background-color: black;
}

.statusButtonActive:focus {
  border-radius: 0;
  padding: 0px 1.5vw;
  border: 1px solid #bcbcbc;
  color: white;
  background-color: black;
}

.cornerLeft {
  border-radius: 10px 0px 0px 10px !important;
}
.cornerRight {
  border-radius: 0px 10px 10px 0px !important;
}

.smallFont {
  font-size: 8px;
  margin: 0;
}

.ant-table .layout-preview-card {
  font-size: 8px;
  width: 200px;
  max-width: 200px !important;
  height: 93px !important;
  border-radius: 12px !important;
  margin-top: 0;
  padding: 0;
}
.ant-table .ant-card-meta-avatar {
  float: left;
  width: 20px !important;
  height: 20px !important;
  padding-right: 8px !important;
}
.ant-table .layout-preview-card .ant-card-meta {
  margin: 5px !important;
}
.ant-table .layout-preview-card .ant-avatar.image-preview,
.ant-table .layout-preview-card .ant-avatar.icon-preview {
  width: 32px !important;
  height: 32px !important;
}

// .ant-table .layout-preview-card .ant-card-body {
//   padding: 5px !important;
// }
.ant-table .layout-preview-card .view-detail {
  margin: 0px 5px 5px 0 !important;
  font-size: 8px !important;
  float: right;
  color: #fff;
}

.layout-preview-card .ant-card-body {
  padding: 18px;
}

.ant-table .layout-preview-card .meta-content-container {
  height: 52px !important;
  display: flex;
  align-items: center;
}

.ant-table .layout-preview-card .subtitle-font-style {
  font-size: 6px !important;
}

.ant-table .layout-preview-card .bg-image-text {
  height: 69px !important;
  display: flex;
  align-items: center;
}

.static-recommendation-table-tool {
  .sw-table-info {
    visibility: hidden;
    display: none;
    // margin-top: 12px;
  }
  .ant-divider-horizontal {
    display: none;
  }
}

.rec-row {
  min-height: 90px;
  .ant-card {
    // margin-top: 10px;
    // margin-bottom: 10px;
    background-color: #fafafa;
    .ant-card-body {
      padding: 10px 5px 5px 5px;
      // padding-top: 15px;
      // padding-right: 10px;
      // padding-bottom: 10px;
      // padding-left: 10px;
    }
  }
  .p {
    margin: auto;
  }
  .preview-recommendation {
    display: flex;
    padding-bottom: 10px;
    .ant-card-body {
      padding: 7px 10px 5px 5px;
    }
    .icon-text-tmplt {
      display: block;
      .ant-card-meta-avatar {
        margin-top: 5px;
        width: 20px;
        height: 20px;
      }

      .ant-card-meta-detail {
        padding: 5px 0 0 20px;
      }
    }

    .image-text-tmplt {
      display: block;
      .ant-card-meta-avatar {
        margin-top: 5px;
      }

      .ant-card-meta-detail {
        padding: 5px 0 0 20px;
      }
    }
    .only-text-tmplt {
      display: block;
      .ant-card-meta-detail {
        padding: 8px 0 0 0px;
      }
      .view-details {
        padding-top: 35px;
      }
    }

    .view-detail {
      margin: 0 5px 5px 0 !important;
      font-size: 8px !important;
      float: right;
      color: #fff;
    }
    .bg-image-text {
      padding: 0;
    }
  }

  // text-align: center;
}

.hide-reload .sw-table-tools-buttons .anticon-reload {
  visibility: hidden;
  width: 0px;
}
.ant-dropdown-menu-item-active {
  color: #0197fe;
}
.ant-dropdown-menu-item-active a {
  color: #0197fe;
}
.ant-dropdown-menu-item a span {
  padding-left: 4px;
}
.ant-tag {
  margin: 0px;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.ant-table-filter-dropdown {
  left: 180px !important;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-left: 30px;
  padding-right: 10px !important;
}
