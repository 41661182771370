.form-page-container {
  .recommendations-tab {
    padding: 24px 24px 0 22px;
    .ant-tabs-extra-content {
      line-height: 15px;
      padding-top: 13px;
      .ant-tabs-new-tab {
        height: 30px;
        width: 30px;
        margin-left: 3px;
        font-size: 20px;
        font-weight: bold;
        color: white;
        background-color: #01cafe;
        border-radius: 5px;
      }
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
      height: 55px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      height: 65px;
      width: 200px;
      line-height: 35px;
      border-radius: 10px;
      .tabs-title {
        width: 145px;
        display: inline-table;
        .recommendation-index {
          color: rgba(0, 0, 0, 0.45);
          font-size: 10px;
          margin: 0;
          height: 18px;
        }
        .recommendation-title {
          margin: 0;
          color: black;
          font-size: 13px;
          font-weight: bold;
          height: 29px;
          width: 145px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-tabs-close-x {
        font-size: 15px;
        width: 37px;
        height: 37px;
        // margin: 10px 0 0 0;
        padding: 19px 5px 0 0;
      }
    }
    .ant-tabs-bar {
      display: inline;
      border-bottom: none;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
      .ant-tabs-nav-list {
        .ant-tabs-nav-add {
          // background: #01cafe;
          // color: white;
          // font-size: 20px;
          // border-radius: 5px;
          // padding: 0;
          color: #01cafe;
          border: 1px solid rgba(1, 202, 254, 0.3);
          border-radius: 5px;
        }
        .ant-tabs-tab {
          width: 200px;
          height: 57px;
          border-radius: 10px 10px 0 0;
          .recommendation-index {
            color: rgba(0, 0, 0, 0.45);
            font-size: 10px;
          }
          .recommendation-title {
            color: black;
            font-size: 13px;
            font-weight: bold;
            width: 145px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-shadow: none;
          }
          .ant-tabs-tab-remove {
            font-size: 15px;
          }
        }
      }
      .ant-tabs-nav-operations {
        .ant-tabs-nav-add {
          // background: #01cafe;
          // color: white;
          // font-size: 20px;
          // border-radius: 5px;
          // padding: 0;
          color: #01cafe;
          border: 1px solid rgba(1, 202, 254, 0.3);
          border-radius: 5px;
        }
      }
    }
    // .ant-tabs-nav-container-scrolling {
    //   padding: 0;
    // }
    // .ant-tabs-tab-prev {
    //   display: none;
    // }
    // .ant-tabs-tab-next {
    //   display: none;
    // }
    // .ant-tabs-tab-arrow {
    //   display: none;
    // }
  }
  .page-content {
    padding: 0px 24px 0 22px;
    .layout-preview-card .ant-card-meta-avatar {
      .icon-preview {
        width: 50px;
        height: 50px;
      }
    }
    .recommendations-form {
      padding-top: 30px;
    }
    .layout-preview-card .ant-card-meta {
      margin: 5px 15px 0 5px;
    }
    .recommendation-form-content {
      margin: 0 0 0 1px;
    }
    .translation-form {
      display: block;
      padding-top: 25px;
    }
    .action-form {
      padding-top: 35px;
    }
    .country-form {
      display: block;
      padding-top: 15px;
    }
    .os-version-range {
      padding-top: 15px;
    }
    .no-campaign {
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      margin: 100px 30% 50px 30%;
      padding: 50px 0 50px 0;
      font-size: large;
      text-align: center;
      background-color: white;
    }
  }
}

.ant-tabs-dropdown .ant-tabs-dropdown-menu {
  .recommendation-index {
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
  }
  .recommendation-title {
    color: black;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: none;
  }
  .ant-tabs-dropdown-menu-item-remove {
    font-size: 15px;
  }
}
